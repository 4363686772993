.footer {
    background-color: #333;
    color: #ffffff;
    padding: 2rem 0;
    text-align: center;
  }
  
  .footer-content {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .footer-content h3 {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .footer-copyright {
    font-size: 0.8rem;
  }