.podcast {
    margin: 2rem;
  }
  
  .podcast-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .podcast-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .podcast-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 1rem;
  }
  
  .podcast-info {
    width: calc(50% - 2rem);
    font-size: 1rem;
  }
  
  .spotify-link {
    background-color: #1db954;
    border-radius: 25px;
    color: #ffffff;
    display: inline-block;
    font-size: 1rem;
    margin-top: 1rem;
    padding: 8px 16px;
    text-decoration: none;
  }