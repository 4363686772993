.projects {
    margin: 2rem;
  }
  
  .projects-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .projects-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .coming-soon-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 1rem;
  }
  
  .projects-content p {
    width: calc(50% - 2rem);
    font-size: 1rem;
  }