.header {
    background-color: #1a73e8;
    padding: 20px 0;
    text-align: center;
  }
  
  .header-title {
    color: #ffffff;
    font-size: 2rem;
    margin: 0;
  }